<template>
<div id="editForm">
    <vs-sidebar parent="editForm" hidden-background click-not-close position-right class="items-no-padding" v-model="active">
        <div class="h-full">
            <div class="customizer-header mt-6 flex items-center justify-between px-6">
                <div>
                    <h4>Add New Season Pass</h4>
                </div>
            </div>
            <vs-divider class="mb-0" />
            <div class="vx-col w-full mb-base">
                <vs-alert v-if="this.errorMessage.length > 0" color="danger" icon="new_releases">
                    {{ errorMessage }}
                </vs-alert>
                <vx-card no-shadow>
                    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                        <form @submit.prevent="handleSubmit(saveSeasonPass)">
                            <vs-row class="mt-2 mb-4">
                                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
                                    <span>Name:</span>
                                </vs-col>
                                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
                                    <ValidationProvider name="name" class="w-full" :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max:100 }" v-slot="{ errors }" :custom-messages="customMessages">
                                        <vs-input class="w-full" name="name" v-model="currentPass.name" />
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </vs-col>
                            </vs-row>
                            <vs-row class="mt-2 mb-4">
                                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
                                    <span>Select Card Color:</span>
                                </vs-col>
                                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
                                    <compact-picker class="w-full m-2" @input="updateValue" :value="colors" :palette="defaultColors"></compact-picker>
                                </vs-col>
                            </vs-row>
                            <vs-row class="mt-2 mb-4">
                                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
                                    <span>Price Inc. GST:</span>
                                </vs-col>
                                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
                                    <ValidationProvider class="w-full" name="priceIncGST" :rules="{ regex: /^[0-9.]*$/, required: true, max: 100 }" v-slot="{ errors }" :custom-messages="customMessages">
                                        <vs-input class="w-full" name="priceIncGST" v-model="currentPass.priceIncGst" />
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </vs-col>
                            </vs-row>
                            <vs-row class="mt-2 mb-4">
                                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
                                    <span>Pass Start Date:</span>
                                </vs-col>
                                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
                                    <ValidationProvider class="w-full" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages" name="startDate">
                                        <flat-pickr label="Start Date" v-model="currentPass.startDate" :config="startDateTimeConfig" class="w-full startDate" name="startDate" />
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </vs-col>
                            </vs-row>
                            <vs-row class="mt-2 mb-4">
                                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
                                    <span>Pass End Date:</span>
                                </vs-col>
                                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
                                    <ValidationProvider class="w-full" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages" name="endDate">
                                        <flat-pickr label="End Date" v-model="currentPass.endDate" :config="endDateTimeConfig" class="w-full" name="endDate" />
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </vs-col>
                            </vs-row>
                            <vs-row class="mt-2 mb-4">
                                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
                                    <span>Sale Date:</span>
                                </vs-col>
                                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
                                    <ValidationProvider class="w-full" :rules="{ required: false }" v-slot="{ errors }" :custom-messages="customMessages" name="onsaleDate">
                                        <flat-pickr label="Sell Date" v-model="currentPass.onSaleDate" :config="sellDateTimeConfig" class="w-full" name="onsaleDate" />
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </vs-col>
                            </vs-row>
                            <vs-row class="mt-2 mb-4">
                                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
                                    <span>Pass Available for Postcodes:</span>
                                </vs-col>
                                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
                                    <vs-chips placeholder="Postcodes" v-model="currentPass.allowedPostcode">
                                        <vs-chip :key="chip" v-for="chip in currentPass.allowedPostcode" @click="remove(chip)" closable>
                                            {{ chip }}
                                        </vs-chip>
                                    </vs-chips>
                                </vs-col>
                            </vs-row>
                            <vs-divider />
                            <vs-row class="mt-2 mb-4">
                                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
                                    <span></span>
                                </vs-col>
                                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
                                    <vs-button class="mb-2" @click.prevent="saveSeasonPass">Submit</vs-button>
                                    <vs-button class="ml-2 mb-2" color="dark" @click="closeWindow">Cancel</vs-button>
                                </vs-col>
                            </vs-row>
                        </form>
                    </ValidationObserver>
                </vx-card>
            </div>
        </div>
    </vs-sidebar>
</div>
</template>

<script>
import {
    productData
} from '../../store/api/product';

import {
    customMessages
} from './../../filters/validationmessage'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import {
    Compact
} from 'vue-color'

const defaultColors = [
    '#4D4D4D', '#999999', '#F44E3B', '#D33115', '#FE9200', '#E27300', '#FCC400',
    '#DBDF00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF',
]

export default {
    props: {
        openAddWindow: {
            type: Boolean,
            required: true,
            default: false
        },
        selectedPass: {
            type: Object,
            default: () => {},
        },
        selectedDateConfig: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        flatPickr,
        'compact-picker': Compact,
    },
    data() {
        return {
            colors: {},
            defaultColors,
            currentPass: {
                ...this.selectedPass
            },
            active: {
                ...this.openAddWindow
            },
            errorMessage: "",
            customMessages,
            selectedColor: "",
            startDateTimeConfig: {
                altFormat: "d-m-Y",
                altInput: true,
                allowInput: true,
                dateFormat: "Y-m-d",
                enableTime: false,
                time_24hr: false
            },
            endDateTimeConfig: {
                minDate: new Date(),
                altFormat: "d-m-Y",
                altInput: true,
                allowInput: true,
                dateFormat: "Y-m-d",
                enableTime: false,
                time_24hr: false
            },
            sellDateTimeConfig: {
                altFormat: "d-m-Y",
                altInput: true,
                allowInput: true,
                dateFormat: "Y-m-d",
                enableTime: false,
                time_24hr: false
            }
        };
    },
    watch: {
        openAddWindow: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.active = newValue;
            }
        },
        selectedPass: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.currentPass = newValue;
                if (!newValue.allowedPostcode) {
                    this.currentPass.allowedPostcode = [];
                } else {
                    this.currentPass.allowedPostcode = this.currentPass.allowedPostcode.split(",");
                }
                if (newValue.statusColor) {
                    this.colors = newValue.statusColor
                }
            }
        },
        selectedDateConfig: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                if (newValue.maxDate) {
                    this.startDateTimeConfig = {
                        ...this.startDateTimeConfig,
                        maxDate: newValue.maxDate
                    };
                    this.endDateTimeConfig = {
                        ...this.endDateTimeConfig,
                        clickOpens: false
                    };
                }
            }
        }
    },
    methods: {
        remove(item) {
            if (this.currentPass.allowedPostcode) {
                this.currentPass.allowedPostcode.splice(this.currentPass.allowedPostcode.indexOf(item), 1)
            }
        },
        updateValue(color) {
            this.currentPass.statusColor = color.hex;
        },
        async saveSeasonPass() {
            const success = await this.$refs.form.validate();
            if (!success) {
                return;
            }

            if (!this.currentPass.onSaleDate) {
                this.currentPass.onSaleDate = null
            }

            let apiResult = await productData.SaveProduct(this.currentPass);
            if (!apiResult.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }
            this.$vs.notify({
                title: "Success",
                text: "Record Saved",
                color: "success",
                position: "top-center"
            });
            this.$emit("onClose");
        },
        closeWindow() {
            this.$emit("onClose");
        }
    }
}
</script>

<style lang="scss">
#editForm {
    .vs-sidebar {
        position: fixed;
        z-index: 52000;
        min-width: 40vw;
        margin: 10px;
        max-height: 97vh;
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    }
}
</style>
